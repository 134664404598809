import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CashHandlingEquipmentCard from '../../components/CashHandlingEquipmentCard';

const CashHandlingEquipmentPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Cash Handling Equipment" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full mx-auto max-w-7xl">
        <CashHandlingEquipmentCard
          to="2-pocket-note-counter"
          h2="MIB-5 Two-Pocket Note Counter"
          h3="Only pay for the functionality you need"
          fluid={data.mib5.childImageSharp.fluid}
        >
          The MIB-5 two-pocket note counter will count and sort mixed
          denominations while simultaneously scanning for counterfeit notes and
          off-sorting TITO tickets if required.
        </CashHandlingEquipmentCard>

        <CashHandlingEquipmentCard
          to="mixed-note-value-sorter"
          h2="MIB-9 Mixed Note Value Counter And Sorter"
          h3="Modern Design, Friendly Interface"
          fluid={data.mib9.childImageSharp.fluid}
        >
          A full-featured unit with a sleek modern design and user-friendly
          interface, the MIB9 allows operators to perform daily cash processing
          jobs without interruption.
        </CashHandlingEquipmentCard>

        <CashHandlingEquipmentCard
          to="2-pocket-counter-sorter"
          h2="MIB-11 Two Pocket Mixed Note Value Counter And Sorter"
          h3="Excellence that exceeds your expectations"
          fluid={data.mib11.childImageSharp.fluid}
        >
          MIB-11 Two Pocket Mixed Note Value Counter And Sorter, designed to
          achieve growing demand for efficient cash management in back office
          processes.
        </CashHandlingEquipmentCard>

        <CashHandlingEquipmentCard
          to="sb-3000"
          h2="SB-3000 3 Pocket Sorter"
          h3="High volume with a low profile"
          fluid={data.sb3000.childImageSharp.fluid}
        >
          The world's smallest 3 pocket sorter designed for high volume
          counting, the SB-3000 uses cutting edge twin Image Processing
          Technology to recognise banknotes and TITO tickets and discriminates
          between them.
        </CashHandlingEquipmentCard>
        <CashHandlingEquipmentCard
          to="smart-clear"
          h2="SmartClear Note Clearance System"
          h3="Efficient On-Floor Clearance And Compliance"
          fluid={data.SmartClear.childImageSharp.fluid}
        >
          The SmartClear Note Clearance System is a competitively priced, highly
          functional EGM clearance system which seamlessly integrates into your
          floor management or back office system.
        </CashHandlingEquipmentCard>
        <CashHandlingEquipmentCard
          to="multi-pocket-fitness-sorter"
          h2="PA9500 Multi-Pocket Fitness Sorter"
          h3="Restlessly in search of
          greater performance"
          fluid={data.nc9500.childImageSharp.fluid}
        >
          PA9500, another revolutionary product from PA, is so far the most
          powerful fitness sorter in PA product portfolio, featuring expandable
          modular stacker design plus enhanced and reliable banknote
          authentication and fitness-sorting.
        </CashHandlingEquipmentCard>
      </div>
    </Layout>
  );
};

CashHandlingEquipmentPage.propTypes = {
  data: PropTypes.object,
};

export default CashHandlingEquipmentPage;

export const query = graphql`
  query CashHandlingEquipmentQuery {
    SmartClear: file(relativePath: { eq: "smartclear.png" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MoneyMachine: file(relativePath: { eq: "money-machine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    NoteAndTicketScanner: file(
      relativePath: { eq: "note-and-ticket-scanner.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    NoteScanner: file(relativePath: { eq: "note-scanner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    CurrencySorter: file(
      relativePath: { eq: "jetscan-ifx-i400-multi-pocket-currency-sorter.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nc9500: file(relativePath: { eq: "products/nc9500/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mib11: file(relativePath: { eq: "products/mib11/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mib9: file(relativePath: { eq: "products/mib9/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mib5: file(relativePath: { eq: "products/mib5/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sb3000: file(relativePath: { eq: "products/sb3000/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
