import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const CashHandlingEquipmentCard = ({ to, h2, h3, children, fluid }) => {
  return (
    <div className="flex w-full p-4 md:w-1/2 lg:w-1/3">
      <Link
        className="flex flex-col flex-1 p-4 border rounded shadow hover:shadow-md"
        to={`/cash-handling-equipment/${to}`}
      >
        <h2 className="text-sm font-bold leading-none tracking-wide text-center uppercase text-brand-blue">
          {h2}
        </h2>
        <h3 className="mb-6 text-3xl font-bold leading-none text-center">
          {h3}
        </h3>
        <div className="flex items-center justify-center w-full h-full max-w-sm mx-auto">
          <Image className="flex-1" fluid={fluid} />
        </div>
        <p className="mt-auto">{children}</p>
        <p className="mt-4 font-bold text-brand-blue hover:text-blue-700">
          Read more &rarr;
        </p>
      </Link>
    </div>
  );
};

CashHandlingEquipmentCard.propTypes = {
  to: PropTypes.string,
  h2: PropTypes.string,
  h3: PropTypes.string,
  fluid: PropTypes.string,
  children: PropTypes.array,
};

export default CashHandlingEquipmentCard;
